const navLinks = [
  { title: 'info', href: '#linkForInfoMobile' },
  { title: 'projects', href: '#projects-section' },
  { title: 'selected clients', href: '#linkForSelectedClientsMobile' },
  { title: 'contact us', href: '#selected-clients-section' },
];

const navLinksLarge = [
  { title: 'info,', href: '#logo-bottom-section' },
  { title: 'projects,', href: '#projects-section' },
  { title: 'selected clients,', href: '#linkForSelectedClientsMobile' },
  { title: 'contact us', href: '#selected-clients-section' },
];

export { navLinks, navLinksLarge };
