import styled from 'styled-components';
import theme from '../../../../../design-system/theme';

const Wrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  // position: 'absolute',
  // sm
  backgroundColor: theme.colors.red,
  paddingTop: 40,
  paddingRight: 25,
  paddingBottom: 25,
  paddingLeft: 25,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.colors.blue,
    paddingTop: 70,
    paddingRight: 41,
    paddingBottom: 41,
    paddingLeft: 41,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    paddingTop: 110,
    paddingRight: 162,
    paddingBottom: 34,
    paddingLeft: 162,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingTop: 150,
    paddingRight: 242,
    paddingLeft: 242,
    paddingBottom: 50,
  },
}));

const MobileCloseIconHolder = styled('div')(({ ...props }) => ({
  ...props,
  position: 'absolute',
  cursor: 'pointer',
  top: 13,
  right: 25,
  [theme.breakpoints.up(['md'])]: {
    top: 24,
    right: 41,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const NameAndTitleContainer = styled('div')(({}) => ({
  paddingBottom: 3,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 10,
  },
}));

const CaptionContainer = styled('div')(({}) => ({
  paddingBottom: 25,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 10,
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: 0,
  },
}));

const CounterContainer = styled('div')(({}) => ({
  [theme.breakpoints.up('lg')]: {
    paddingTop: 10,
  },
}));

export {
  Wrapper,
  MobileCloseIconHolder,
  NameAndTitleContainer,
  CaptionContainer,
  CounterContainer,
};
