import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@material-ui/core';

import {
  Wrapper,
  MobileCloseIconHolder,
  NameAndTitleContainer,
  CaptionContainer,
  CounterContainer,
} from './ProjectPage.styles';

import Carousel from './components/carousel/Carousel';
import CarouselTwo from './components/carousel/CarouselTwo';
import Typography from '../../../../../design-system/component-library/Typography';

const ProjectPage = ({ selectedProject, closeProjectPage }) => {
  const { project, date, caption, images } = selectedProject;

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const projectDetailsRef = React.useRef();

  const [projectDetailsRefHeight, setProjectDetailsRefHeight] = React.useState(
    0
  );

  React.useEffect(() => {
    if (projectDetailsRef?.current?.height) return;
    setProjectDetailsRefHeight(projectDetailsRef.current.offsetHeight);
  }, [projectDetailsRef]);

  return (
    <>
      <Wrapper>
        <MobileCloseIconHolder onClick={closeProjectPage}>
          <img src="images/icons/close.png" />
        </MobileCloseIconHolder>
        <div
          style={{
            height: '100%',
          }}
        >
          <CarouselTwo
            images={images}
            projectDetailsRefHeight={projectDetailsRefHeight}
            closeProjectPage={closeProjectPage}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
          <div
            style={{ backgroundColor: 'white', paddingBottom: 10 }}
            ref={projectDetailsRef}
          >
            <NameAndTitleContainer>
              <Typography variant="projectPageProjectName">
                {project}
              </Typography>
              <Typography variant="projectPageProjectDate"> {date}</Typography>
            </NameAndTitleContainer>
            <CaptionContainer>
              <Typography variant="projectPageProjectCaption">
                {caption}
              </Typography>
            </CaptionContainer>
            <CounterContainer>
              <Typography variant="projectPageProjectCounter">
                {currentIndex + 1}/{images.length}
              </Typography>
            </CounterContainer>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

// ProjectPage.propTypes = {};

export default ProjectPage;
