import styled from 'styled-components';
import theme from '../../../design-system/theme';

const Wrapper = styled('div')(() => ({
  // position: 'absolute',
  backgroundColor: theme.colors.red,
  paddingLeft: 25,
  paddingRight: 25,
  paddingBottom: 40,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.colors.blue,
    paddingLeft: 41,
    paddingRight: 41,
    paddingBottom: 60,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    paddingLeft: 90,
    paddingRight: 90,
    paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingLeft: 135,
    paddingRight: 135,
    paddingBottom: 285,
  },
}));

const ProjectsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}));

export { Wrapper, ProjectsContainer };
