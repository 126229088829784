import React from 'react';
import '../../../../src/styles/global.css';
import styled from 'styled-components';

const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const MainLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MainLayout;
