import styled from 'styled-components';
import theme from '../../../../../design-system/theme';

const ProjectStyles = {
  a: {
    maxWidth: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
      marginBottom: 30,
      marginRight: 30,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 800,
      marginBottom: 50,
      marginRight: 50,
    },
  },
  b: {
    maxWidth: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
      marginBottom: 30,
      marginRight: 30,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 700,
      marginBottom: 50,
      marginRight: 50,
    },
  },
  c: {
    maxWidth: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 400,
      marginBottom: 30,
      marginRight: 70,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 500,
      marginBottom: 50,
      marginRight: 100,
    },
  },
  d: {
    maxWidth: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
      marginBottom: 30,
      marginRight: 70,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 500,
      marginBottom: 50,
      marginRight: 100,
    },
  },
  e: {
    maxWidth: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 200,
      marginBottom: 30,
      marginRight: 30,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 400,
      marginBottom: 50,
      marginRight: 50,
    },
  },
};

const ProjectContainer = styled('div')((props) => ({
  position: 'relative',
  display: 'inline-block',
  color: 'black',
  cursor: 'pointer',
  ...ProjectStyles[props.projectType],
  flexGrow: 1,
}));

const TitleContainer = styled('div')((props) => ({
  paddingBottom: 16,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 25,
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: 0,
  },
}));

export { ProjectContainer, TitleContainer };

// const ProjectA = styled('div')(() => ({
//   maxWidth: 600,
//   marginBottom: 30,
//   marginRight: 30,
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: 800,
//     marginBottom: 50,
//     marginRight: 50,
//   },
// }));

// const ProjectB = styled('div')(() => ({
//   maxWidth: 500,
//   marginBottom: 30,
//   marginRight: 30,
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: 700,
//     marginBottom: 50,
//     marginRight: 50,
//   },
// }));

// const ProjectC = styled('div')(() => ({
//   maxWidth: 400,
//   marginBottom: 30,
//   marginRight: 70,
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: 500,
//     marginBottom: 50,
//     marginRight: 100,
//   },
// }));

// const ProjectD = styled('div')(() => ({
//   maxWidth: 300,
//   marginBottom: 30,
//   marginRight: 70,
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: 500,
//     marginBottom: 50,
//     marginRight: 100,
//   },
// }));

// const ProjectE = styled('div')(() => ({
//   maxWidth: 200,
//   marginBottom: 30,
//   marginRight: 30,
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: 400,
//     marginBottom: 50,
//     marginRight: 50,
//   },
// }));
