const projectsData = [
  // 01 B
  {
    project: 'Boiler Room x Juventus, Brooklyn, New York',
    projectType: 'b',
    date: '2017',
    caption:
      'Design of a temporary space for the collaboration between Boiler Room New York and Juventus, including stage and environment design and custom floor mural.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/1-d_nblsrw.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/2-d_cqm1py.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/3-d_ngilzr.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/4-d_fnbcrl.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/5-d_omjjdc.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608402793/01-Boiler-Room-x-Juventes/6-d_pcdagn.jpg',
    ],
  },
  // 02 B
  {
    project: 'Awake Asics, The Next Door, Paris',
    projectType: 'b',
    date: '2019',
    caption:
      'Retail concept and installation design for the collaboration between Awake NY and Asics at The Next Door, Paris.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608407394/02-Awake-Asics_Paris/1-d_uao1n8.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608407394/02-Awake-Asics_Paris/2-d_izdltc.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608407394/02-Awake-Asics_Paris/3-d_sdhoed.jpg',
    ],
  },
  // 03 C
  {
    project: 'Baque Creative, Navy Yards, Brooklyn, NY',
    projectType: 'c',
    date: '2020',
    caption:
      'Office and collection display design for Baque Creative and Awake NY. New Body planned the design of the office space to include a custom collection display alongside antique furniture and light fixtures.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608410588/03-Awake-Navy-Yard/1-d_gy4fdb.jpg',
    ],
  },
  // 04 B
  {
    project: '10th Street',
    projectType: 'b',
    date: '2018',
    caption:
      'Refurbishment and renovation of an apartment in Manhattan’s Greenwich Village. The project included careful design of millwork pieces, a kitchen and bathroom renovation.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608410775/04-10th-Street/1-d_r0ij8b.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608410775/04-10th-Street/2-d_dvrhgm.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608410775/04-10th-Street/3-d_zole6m.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608410775/04-10th-Street/4-d_vkvvzm.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608410775/04-10th-Street/5-d_qfk3pe.jpg',
    ],
  },
  // 05 A
  {
    project: 'Notes on Solidarity: Tricontinentalism in Print',
    projectType: 'a',
    date: '2019',
    caption:
      // 'Exhibition design for The James Gallery at The Graduate Center, CUNY. Curated by Debra Lennard, this exhibition explores a chapter of the anti-colonial struggles after World War Two in Asia, Africa and Latin America. New Body designed furniture and custom vitrines for this striking presentation of posters, books, newspapers and magazines.Exhibition design for The James Gallery at The Graduate Center, CUNY. Curated by Debra Lennard, this exhibition explores a chapter of the anti-colonial struggles after World War Two in Asia, Africa and Latin America. New Body designed furniture and custom vitrines for this striking presentation of posters, books, newspapers and magazines.',
      'Exhibition design for The James Gallery at The Graduate Center, CUNY. Curated by Debra Lennard, this exhibition explores a chapter of the anti-colonial struggles after World War Two in Asia, Africa and Latin America. New Body designed furniture and custom vitrines for this striking presentation of posters, books, newspapers and magazines.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608484076/06-OSPAAAL/1-d_dlmegc.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484076/06-OSPAAAL/2-d_chlbf9.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484076/06-OSPAAAL/3-d_beean8.jpg',
    ],
  },
  // 06 B
  {
    project: 'Boiler Room New York',
    projectType: 'b',
    date: '2017',
    caption:
      'Proposed interior refurbishment and renovation of a storefront office space in Brooklyn, New York. The project included custom millwork for a communal work space, private meeting rooms and kitchen and bathroom renovation.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608484632/07-Boiler-Room-Office/1-d_cbzo5x.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484633/07-Boiler-Room-Office/2-d_xevbkr.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484632/07-Boiler-Room-Office/3-d_uqxuh9.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484632/07-Boiler-Room-Office/4-copy-d_kohzjb.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484633/07-Boiler-Room-Office/5-copy-d_qwxudr.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608484632/07-Boiler-Room-Office/6-d_qto4fk.jpg',
    ],
  },
  // 07 C
  {
    project: 'Baque Creative, Canal St, NYC',
    projectType: 'c',
    date: '2018',
    caption:
      'Office and reference library design for Baque Creative and Awake NY. New Body planned the refurbishment of the existing office space to include a reference library accessible to the public, including custom millwork alongside antique furniture and light fixtures sourced by New Body.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/1-d_gndvov.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/2-d_fkh3j8.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/3-d_d8cmng.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/4-d_fqllba.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/5-d_rujrm3.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485268/09_Baque%20Creative%20Canal/6-d_z1t2kp.jpg',
    ],
  },
  // 08 D
  {
    project: 'Cedro in Mexico City',
    projectType: 'd',
    date: '2020',
    caption:
      'Residential apartment in a 19th century family house. Under construction.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608485502/10_Cedro%20Mexico%20City/1-d_njj8qy.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485502/10_Cedro%20Mexico%20City/2-d_cfcmjo.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485502/10_Cedro%20Mexico%20City/3-d_o6akz2.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485502/10_Cedro%20Mexico%20City/4-d_p9f9xa.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608485502/10_Cedro%20Mexico%20City/5-d_v7mr1v.jpg',
    ],
  },
  // 09 D
  {
    project: 'Awake NY, Procell, NYC',
    projectType: 'd',
    date: '2018',
    caption:
      'Retail concept and custom display design for Awake NY’s residency at Procell, NYC.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608686374/12-Awake-Procell/1-d_u3oady.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608686375/12-Awake-Procell/2-d_zxyw6b.jpg',
    ],
  },
  // 12 b
  {
    project: 'Cobble Hill Residence',
    projectType: 'b',
    date: '2016',
    caption:
      'Complete apartment renovation including new roof and outdoor space.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1609732148/16-Clinton-Street/1-d_hpc5iv.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1609732147/16-Clinton-Street/2-d_eutjyj.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1609732147/16-Clinton-Street/3-d_j6ok1s.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1609732147/16-Clinton-Street/4-d_zjotid.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1609732147/16-Clinton-Street/5-d_bkqo64.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1609732148/16-Clinton-Street/6-d_x55itn.jpg',
    ],
  },
  // 13 e START
  {
    project: 'Medea Selfridges, London',
    projectType: 'e',
    date: '2018',
    caption:
      'Retail concept and trend wall design for Medea’s retail showcase at Selfridges, London.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1608486027/18-Medea/1-d_samncx.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608486028/18-Medea/2-d_vgwc7d.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1608486027/18-Medea/3-d_s0o12o.jpg',
    ],
  },
  // 13 e END
  // 14 a START
  {
    project: 'Forgotten Limbs, Cassi Namoda, François Ghebaly, Los Angeles',
    projectType: 'a',
    date: '2021',
    caption:
      'Creative consultation and exhibition design for Cassi Namoda and François Ghebaly gallery.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1657063144/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/1_vokbw8.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063140/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/2_lo3rcp.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063139/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/3_ljr0vd.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063140/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/4_loqzpk.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063138/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/5_uqvflp.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063136/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/6_hbqfg9.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1657063141/23_Forgotten%20Limbs%2C%20Cassi%20Namoda%2C%20Fran%C3%A7ois%20Ghebaly%2C%20Los%20Angeles%202021/7_ksqbtv.jpg',
    ],
  },
  // 14 a END
  // 15 a START
  {
    project: 'Little Flower Café, Astoria, New York',
    projectType: 'b',
    date: '2022',
    caption:
      'Renovation of an existing restaurant space to a new Café in Astoria, NY.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1662491007/24_Little%20Flower/01_tdf5qe.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1662491007/24_Little%20Flower/02_bhs5co.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1662491009/24_Little%20Flower/03_kswqrw.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1662491007/24_Little%20Flower/04_kh6vy0.jpg',
    ],
  },
  // 15 a END
  // 16 a START
   {
     project: 'Enly, Soho, New York',
     projectType: 'c',
    date: '2022',
    caption:
      'Café & retail space for ENLY NYC.',
    images: [
      'https://res.cloudinary.com/newbody/image/upload/v1665510581/25_Enly/1_tniom8.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1665510581/25_Enly/2_zihnli.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1665510581/25_Enly/3_zpq5wu.jpg',
      'https://res.cloudinary.com/newbody/image/upload/v1665510581/25_Enly/4_z4wfsl.jpg',
     ],
    },
  // 16 a END
]
export default projectsData;