import React from 'react';
import '../../../../src/styles/global.css';
import styled from 'styled-components';

import theme from '../../../design-system/theme';

import Typography from '../../../design-system/component-library/Typography';

const Wrapper = styled.section(() => ({
  backgroundColor: theme.colors.red,
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 56,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.colors.blue,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 44,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    paddingLeft: 162,
    paddingRight: 162,
    paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingLeft: 243,
    paddingRight: 243,
    paddingBottom: 285,
  },
}));

const Header = styled.header(() => ({
  paddingBottom: 5,
  display: 'block',
  [theme.breakpoints.up('md')]: {
    paddingBottom: 7,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'inline',
    paddingBottom: 0,
  },
}));

const CreditContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    display: 'inline',
  },
}));

const CreditBlock = styled.div(() => ({
  display: 'inline',
}));

const Credits = () => {
  return (
    <Wrapper>
      <Header>
        <Typography variant="creditHeader">CREDITS: </Typography>
      </Header>
      <CreditContainer>
        <CreditBlock>
          <Typography variant="creditTitle">Art Direction </Typography>
          <Typography variant="credit">Daniel Sansavini </Typography>
        </CreditBlock>
        <CreditBlock>
          <Typography variant="creditTitle">Web </Typography>
          <Typography variant="credit">Brandon Murphy </Typography>
        </CreditBlock>
        <CreditBlock>
          <Typography variant="creditTitle">Photography </Typography>
          <Typography variant="credit">
            Jack Barrett, Devlin Claro Resetar, Charles Dorrance-King, Naho
            Kubota, Alex McLean, Brian W. Ferry
          </Typography>
        </CreditBlock>
      </CreditContainer>
    </Wrapper>
  );
};

export default Credits;
