import React from 'react';
import '../../../../src/styles/global.css';
import styled from 'styled-components';

import theme from '../../../design-system/theme';

import Typography from '../../../design-system/component-library/Typography';

const Wrapper = styled.div(() => ({
  display: 'none',
  // backgroundColor: theme.colors.red,
  // backgroundColor: 'red',
  // paddingLeft: 40,
  // paddingRight: 40,
  // paddingBottom: 40,
  // [theme.breakpoints.up('md')]: {
  //   backgroundColor: theme.colors.blue,
  //   paddingLeft: 60,
  //   paddingRight: 60,
  //   paddingBottom: 65,
  // },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    backgroundColor: theme.colors.white,
    paddingLeft: 162,
    paddingRight: 162,
    paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingLeft: 243,
    paddingRight: 243,
    paddingBottom: 285,
  },
}));

const Info = () => {
  return (
    <Wrapper id="info-section">
      <Typography variant="body">
        Established in 2017, New Body is a collective of architects and
        designers. Our work encompasses architecture, furniture, exhibition
        design and art direction.
        <br></br>
        <br></br>
        We have completed projects in New York, Los Angeles, London and Paris
        with a focus in the arts, culture and fashion. Clients include Awake NY,
        Le Bon Marché, Dover Street Market and Studio One.
        <br></br>
        <br></br>
        Each project is a unique story, stemming from a close dialogue between
        client and designer and a collaborative working relationship with
        craftspeople, artists and specialist consultants.
      </Typography>
    </Wrapper>
  );
};

export default Info;
