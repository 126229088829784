import React from 'react';
import '../../../../src/styles/global.css';

import { Dialog } from '@material-ui/core';

import { Wrapper, ProjectsContainer } from './ProjectsList.styles';

import projectsData from './utils/projectsData';

import ProjectPreview from './components/project-preview/ProjectPreview';
import ProjectPage from './components/project-page/ProjectPage';

const ProjectsList = () => {
  const [isProjectSelected, setIsProjectSelected] = React.useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState({});

  const openProjectPageAndSelectProject = (projectIndex) => {
    const newlySelectedProject = projectsData[projectIndex];
    setIsProjectSelected(true);
    setSelectedProjectIndex(projectIndex);
    setSelectedProject(newlySelectedProject);
  };

  const closeProjectPage = () => {
    setIsProjectSelected(false);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  React.useEffect(() => {
    shuffleArray(projectsData);
  }, []);

  const renderProjectProjectPreviews = () => {
    return projectsData.map((project, index) => (
      <ProjectPreview
        title={project.project}
        date={project.date}
        caption={project.caption}
        projectType={project.projectType}
        imageUrl={project.images[0]}
        index={index}
        openProjectPageAndSelectProject={openProjectPageAndSelectProject}
        closeProjectPage={closeProjectPage}
        selectedProjectIndex={selectedProjectIndex}
      />
    ));
  };
  return (
    <>
      <Wrapper id="projects-section">
        <ProjectsContainer>{renderProjectProjectPreviews()}</ProjectsContainer>
        <Dialog open={isProjectSelected} onClose={closeProjectPage} fullScreen>
          <ProjectPage
            selectedProject={selectedProject}
            isProjectSelected={isProjectSelected}
            selectedProjectIndex={selectedProjectIndex}
            closeProjectPage={closeProjectPage}
          />
        </Dialog>
        <div id="linkForSelectedClientsMobile"></div>
      </Wrapper>
    </>
  );
};

export default ProjectsList;
