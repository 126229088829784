import React from 'react';
import { navLinks } from '../../utils/navLinksData';

import {
  MenuButton,
  Line,
  HamburgerMenuContainer,
  HamburgerMenuLink,
  CloseMenuContainer,
} from './HamburgerMenu.styles';

const HamburgerMenu = ({
  openHamburgerMenu,
  closeHamburgerMenu,
  isHamburgerMenuOpen,
}) => {
  return (
    <>
      <HamburgerMenuContainer>
        {!isHamburgerMenuOpen && (
          <MenuButton onClick={openHamburgerMenu}>
            <Line />
            <Line />
            <Line />
          </MenuButton>
        )}
        {isHamburgerMenuOpen && (
          <>
            {navLinks.map((link) => (
              <div style={{}}>
                <HamburgerMenuLink href={link.href}>
                  {link.title}
                </HamburgerMenuLink>
              </div>
            ))}
            <CloseMenuContainer onClick={closeHamburgerMenu}>
              <img src="images/icons/close.png" />
            </CloseMenuContainer>
          </>
        )}
      </HamburgerMenuContainer>
    </>
  );
};

export default HamburgerMenu;
