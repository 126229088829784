import React from 'react';
import styled from 'styled-components';

import theme from '../../../design-system/theme';

const Wrapper = styled.div(() => ({
  display: 'none',
  // backgroundColor: theme.colors.red,
  // paddingLeft: 50,
  // paddingRight: 50,
  // paddingBottom: 40,
  // [theme.breakpoints.up('md')]: {
  //   backgroundColor: theme.colors.blue,
  //   paddingLeft: 40,
  //   paddingRight: 40,
  //   paddingBottom: 60,
  // },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    // backgroundColor: 'green',
    paddingLeft: 162,
    paddingRight: 162,
    height: 'calc(100vh)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    // backgroundColor: theme.colors.green,
    paddingLeft: 243,
    paddingRight: 243,
    // paddingBottom: 285,
  },
}));

const Logo = () => {
  return (
    <Wrapper id="logo-section">
      <img
        src="https://res.cloudinary.com/newbody/image/upload/v1608685922/00-Logo/Logo-d_v1pzhz.jpg"
        style={{
          width: '100%',
        }}
      />
      <div id="logo-bottom-section" />
    </Wrapper>
  );
};

export default Logo;
