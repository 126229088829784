import React from 'react';
import '../../../../src/styles/global.css';
import styled from 'styled-components';

import theme from '../../../design-system/theme';

import Typography from '../../../design-system/component-library/Typography';

const Wrapper = styled.section(() => ({
  backgroundColor: theme.colors.red,
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 23,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.colors.blue,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 44,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    paddingLeft: 162,
    paddingRight: 162,
    paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingLeft: 243,
    paddingRight: 243,
    paddingBottom: 285,
  },
}));

const ContactSection = styled('section')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const ContactBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 28,
  paddingRight: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: 44,
    paddingRight: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: 0,
    paddingRight: 106,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  [theme.breakpoints.up('xl')]: {
    paddingBottom: 0,
    paddingRight: 159,
    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

const Link = styled.a(() => ({
  color: theme.colors.black,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
}));

const Header = styled.header(() => ({
  paddingBottom: 5,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 7,
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: 0,
  },
}));

const contactInfo = {
  email: { title: 'Please contact us', contact: 'studio@new-body.nyc' },
  home: { title: 'Address', contact: '84 Willow Street, Brooklyn, NY 11201' },
  phone: { title: 'Telephone', contact: '(213) 290-5641' },
};

const Info = () => {
  return (
    <Wrapper id="contact-section">
      <ContactSection>
        <ContactBlock>
          <Header>
            <Typography variant="sectionHeader">
              {contactInfo.email.title}
            </Typography>
          </Header>
          <Typography variant="section">
            <Link href={`mailto:${contactInfo.email.contact}`}>
              {contactInfo.email.contact}
            </Link>
          </Typography>
        </ContactBlock>
        <ContactBlock>
          <Header>
            <Typography variant="sectionHeader">
              {contactInfo.home.title}
            </Typography>
          </Header>
          <Typography variant="section">{contactInfo.home.contact}</Typography>
        </ContactBlock>
        <ContactBlock>
          <Header>
            <Typography variant="sectionHeader">
              {contactInfo.phone.title}
            </Typography>
          </Header>
          <Typography variant="section">
            <Link href="tel:3475083061">{contactInfo.phone.contact}</Link>
          </Typography>
        </ContactBlock>
      </ContactSection>
    </Wrapper>
  );
};

export default Info;
