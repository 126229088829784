import styled from 'styled-components';
import theme from '../../../design-system/theme';

const NavWrapper = styled.div(() => ({
  position: 'fixed',
  height: 'fit-content',
  width: '100vw',
  top: 0,
  bottom: 0,
  // backgroundColor: theme.colors.red,
  // backgroundColor: 'red',
  zIndex: 1,
  paddingTop: 25,
  paddingLeft: 25,
  // paddingBottom: 25,
  [theme.breakpoints.up('md')]: {
    // backgroundColor: theme.colors.blue,
    paddingTop: 41,
    paddingLeft: 41,
    // paddingBottom: 41,
  },
  [theme.breakpoints.up('lg')]: {
    // backgroundColor: theme.colors.white,
    // zIndex: 3000,
    paddingTop: 34,
    paddingLeft: 34,
    // paddingBottom: 20,
  },
  [theme.breakpoints.up('xl')]: {
    // backgroundColor: theme.colors.green,
    paddingTop: 50,
    paddingLeft: 50,
    // paddingBottom: 40,
  },
}));

const Logo = styled.div(() => ({
  display: 'none',
  ...theme.typography.navLogo,
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    paddingBottom: 6,
  },
  [theme.breakpoints.up('xl')]: {
    marginBottom: 9,
  },
}));

const NavLink = styled.a(({ activeLinkTitle }) => ({
  ...theme.typography.navHamburger,
  textTransform: 'uppercase',
  color: 'black',
  marginRight: '8px',
  textDecoration: activeLinkTitle ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.navHamburger,
    display: 'inline',
  },
}));

export { NavWrapper, Logo, NavLink };
