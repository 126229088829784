import React from 'react';

import { navLinksLarge } from './utils/navLinksData';
import { NavWrapper, Logo, NavLink } from './Nav.styles';

import HamburgerMenu from './components/hamburger-menu/HamburgerMenu';

const Nav = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = React.useState(false);

  const closeHamburgerMenu = () => {
    setIsHamburgerMenuOpen(false);
  };

  const openHamburgerMenu = () => {
    setIsHamburgerMenuOpen(true);
  };

  return (
    <NavWrapper>
      <HamburgerMenu
        openHamburgerMenu={openHamburgerMenu}
        closeHamburgerMenu={closeHamburgerMenu}
        isHamburgerMenuOpen={isHamburgerMenuOpen}
      />
      <Logo>New Body</Logo>
      <div>
        {navLinksLarge.map((link) => (
          <NavLink href={link.href}>{link.title}</NavLink>
        ))}
      </div>
    </NavWrapper>
  );
};

export default Nav;
