import styled from 'styled-components';
import theme from '../../../../../../../design-system/theme';

const Wrapper = styled('div')(({ projectDetailsRefHeight }) => ({
  height: `calc(100% - ${projectDetailsRefHeight}px)`,
  paddingBottom: 0,
  display: 'block',
}));

const MainContainer = styled('div')(({}) => ({
  display: 'flex',
  backgroundColor: 'purple',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const ResponsiveLayoutForArrows = styled('div')(({}) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexShrink: 1,
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
}));

const StyledImage = styled('img')(({ imageOrientation }) => ({
  maxHeight: 'calc(100% - 88px)',
}));

const MobileButtonContainer = styled('div')(({}) => ({
  backgroundColor: 'skyblue',
  display: 'flex',
  width: '100%',
  flexGrow: 1,
  justifyContent: 'space-between',
  paddingTop: 10,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
    paddingTop: 0,
  },
}));

export {
  Wrapper,
  MainContainer,
  ResponsiveLayoutForArrows,
  StyledImage,
  MobileButtonContainer,
};
