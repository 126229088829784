import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import theme from '../../../../../../../design-system/theme';

import {
  Wrapper,
  MainContainer,
  ResponsiveLayoutForArrows,
  StyledImage,
  MobileButtonContainer,
  // new
  DesktopArrowsContainer,
  MobileArrowsContainer,
  CarouselImageContainer,
  DesktopCloseIcon,
} from './CarouselTwo.styles';

const LeftArrow = ({ changeImage, ...props }) => (
  <div
    onClick={() => changeImage('backward')}
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <img src="/images/icons/left-arrow.png" />
  </div>
);

const RightArrow = ({ changeImage, ...props }) => (
  <div
    onClick={() => changeImage('forward')}
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <img src="/images/icons/right-arrow.png" />
  </div>
);

const DesktopLeftArrow = styled(LeftArrow)(({ props }) => ({
  ...props,
  display: 'none',
  flexShrink: 0,
  paddingRight: 0,
  [theme.breakpoints.up('lg')]: {
    paddingRight: 15,
    cursor: 'pointer',
    display: 'block',
  },
}));

const DesktopRightArrow = styled(RightArrow)(({ props }) => ({
  ...props,
  display: 'none',
  flexShrink: 0,
  paddingLeft: 0,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 15,
    cursor: 'pointer',
    display: 'block',
  },
}));

const Carousel = ({
  images,
  projectDetailsRefHeight,
  closeProjectPage,
  currentIndex,
  setCurrentIndex,
}) => {
  const isThereMoreThanOneImage = images.length > 1;

  const [imageOrientation, setImageOrientation] = React.useState(undefined);
  const [
    mobileArrowContainerHeight,
    setMobileArrowContainerHeight,
  ] = React.useState(0);
  const mobileArrowContainerRef = React.useRef();

  const currentImageSrc = images[currentIndex];
  const imageRef = React.useRef();

  React.useEffect(() => {
    if (!imageRef?.current) return;
    const imageHeight = imageRef.current.height;
    const imageWidth = imageRef.current.width;

    imageHeight > imageWidth
      ? setImageOrientation('vertical')
      : setImageOrientation('horizontal');
  }, [imageRef]);

  // React.useEffect(() => {
  //   if (mobileArrowContainerRef?.current?.height) return;
  //   console.log(mobileArrowContainerRef.current);
  //   console.log(mobileArrowContainerRef.current.offsetHeight);
  //   setMobileArrowContainerHeight(mobileArrowContainerRef.current.offsetHeight);
  // }, [mobileArrowContainerRef]);

  const changeImage = (direction) => {
    const firstImageIndex = 0;
    const isFirstImage = currentIndex === firstImageIndex;

    const lastImageIndex = images.length - 1;
    const isLastImage = currentIndex === lastImageIndex;

    if (direction === 'backward') {
      if (isFirstImage) {
        setCurrentIndex(lastImageIndex);
        return;
      }

      setCurrentIndex((prevIndex) => prevIndex - 1);
      return;
    }

    if (direction === 'forward') {
      if (isLastImage) {
        setCurrentIndex(firstImageIndex);
        return;
      }
      setCurrentIndex((prevIndex) => prevIndex + 1);
      return;
    }
  };

  return (
    <Wrapper projectDetailsRefHeight={projectDetailsRefHeight}>
      <div style={{ height: '100%', position: 'aboslute' }}>
        <DesktopArrowsContainer>
          <CarouselImageContainer>
            {/* <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            > */}
            {/* <DesktopLeftArrow changeImage={changeImage} /> */}
            <DesktopCloseIcon style={{ cursor: 'pointer' }}>
              <div
                onClick={closeProjectPage}
                style={{
                  // width: '20%',
                  backgroundColor: 'skyblue',
                  height: '100%',
                  cursor: 'pointer',
                  border: 0,
                  backgroundColor: 'transparent',
                }}
              >
                <img src="images/icons/close.png" />
              </div>
            </DesktopCloseIcon>
            <StyledImage src={currentImageSrc} ref={imageRef} />
            {/* <DesktopRightArrow changeImage={changeImage} /> */}
            {/* </div> */}
            {isThereMoreThanOneImage && (
              <MobileArrowsContainer>
                <LeftArrow changeImage={changeImage} />
                <RightArrow changeImage={changeImage} />
              </MobileArrowsContainer>
            )}
          </CarouselImageContainer>
        </DesktopArrowsContainer>
      </div>

      {/* <MobileButtonContainer ref={mobileArrowContainerRef}>
          <LeftArrow changeImage={changeImage} />
          <RightArrow changeImage={changeImage} />
        </MobileButtonContainer> */}
      {/* </ResponsiveLayoutForArrows> */}
      {/* </MainContainer> */}
    </Wrapper>
  );
};

Carousel.propTypes = {};

export default Carousel;
