import React from 'react';
import PropTypes from 'prop-types';
import '../../../../src/styles/global.css';
import styled from 'styled-components';

import theme from '../../../design-system/theme';

import Typography from '../../../design-system/component-library/Typography';

const Wrapper = styled.div(() => ({
  height: 'calc(100vh)',
  // backgroundColor: 'pink',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  paddingLeft: 50,
  paddingRight: 50,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const MobileIntro = (props) => {
  return (
    <Wrapper>
      <img
        src="https://res.cloudinary.com/newbody/image/upload/v1608685922/00-Logo/Logo-d_v1pzhz.jpg"
        style={{
          paddingBottom: 40,
        }}
      />
      <Typography variant="body">
        Established in 2017, New Body is a collective of architects and
        designers. Our work encompasses architecture, furniture, exhibition
        design and art direction.
        <br></br>
        <br></br>
        We have completed projects in New York, Los Angeles, London and Paris
        with a focus in the arts, culture and fashion. Clients include Awake NY,
        Le Bon Marché, Dover Street Market and Studio One.
        <br></br>
        <br></br>
        Each project is a unique story, stemming from a close dialogue between
        client and designer and a collaborative working relationship with
        craftspeople, artists and specialist consultants.
      </Typography>
    </Wrapper>
  );
};

MobileIntro.propTypes = {};

export default MobileIntro;
