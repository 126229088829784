const FONTS = {
  fontFamilyDefault: 'Obituary, Arial',
  fontFamilyBold: 'HelveticaNeueHeavy',
  fontSizeXTiny: '10px',
  fontSizeTiny: '11px',
  fontSizeXXSmall: '14px',
  fontSizeXSmall: '16px',
  fontSizeSmall: '20px',
  fontSizeMedium: '24px',
  fontSizeLarge: '34px',
  fontSizeXLarge: '48px',
  fontSizeXXLarge: '60px',
  fontSizeXXXLarge: '96px',
};

export default FONTS;
