import styled from 'styled-components';
import theme from '../../../../../design-system/theme';

const MenuButton = styled.div(() => ({
  width: 30,
  height: '100%',
  cursor: 'pointer',
}));

const Line = styled.div(() => ({
  height: 10,
  borderTop: '1px solid black',
}));

const HamburgerMenuContainer = styled.div(() => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const HamburgerMenuLink = styled.a(({ activeLinkTitle }) => ({
  ...theme.typography.navHamburger,
  color: theme.colors.black,
  textDecoration: activeLinkTitle ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const CloseMenuContainer = styled.div(() => ({
  cursor: 'pointer',
  ...theme.typography.navHamburger,
  fontSize: 25,
  position: 'absolute',
  top: 5,
  right: 25,
  [theme.breakpoints.up('md')]: {
    top: 0,
    right: 41,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export {
  MenuButton,
  Line,
  HamburgerMenuContainer,
  HamburgerMenuLink,
  CloseMenuContainer,
};
