import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import theme from '../theme';

const Component = styled('span')(
  ({ variant, responsiveStylesForProjectPreview }) => ({
    ...theme.typography[variant],
    [theme.breakpoints.up('lg')]: {
      ...responsiveStylesForProjectPreview,
    },
  })
);

const Typography = ({ variant, children, isPreview, isPreviewHovered }) => {
  let responsiveStylesForProjectPreview;

  if (isPreview) {
    responsiveStylesForProjectPreview = {
      color: isPreviewHovered ? 'black' : 'white',
    };
  }

  if (!isPreview) {
    responsiveStylesForProjectPreview = {
      color: 'black',
    };
  }

  return (
    <Component
      variant={variant}
      isPreview={isPreview}
      isPreviewHovered={isPreviewHovered}
      responsiveStylesForProjectPreview={responsiveStylesForProjectPreview}
    >
      {children}
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default Typography;
