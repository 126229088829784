import FONTS from './style-guide/fonts';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const up = (breakpoint) => {
  const selectedBreakpoint = breakpoints[breakpoint];
  if (typeof selectedBreakpoint !== 'number') return;
  return `@media (min-width: ${selectedBreakpoint}px)`;
};

const theme = {
  fonts: { ...FONTS },
  colors: {
    black: '#000000',
    red: 'white',
    blue: 'white',
    white: 'white',
    green: 'white',
  },
  breakpoints: {
    ...breakpoints,
    up,
  },
  spacing: {
    spacingDefault: {
      marginLeft: '15%',
      marginRight: '15%',
    },
    sectionPaddingDefault: {
      paddingBottom: '284px',
    },
  },
  typography: {
    navHamburger: {
      fontFamily: FONTS.fontFamilyBold,
      textTransform: 'uppercase',
      // fontSize: '37px',
      // halfed
      fontSize: '19px',
      lineHeight: '19.40px',
      [up('md')]: {
        // fontSize: '23px',
        // lineHeight: '48.90px',
        fontSize: 23,
        lineHeight: '24.45px',
      },
      [up('lg')]: {
        fontFamily: FONTS.fontFamilyDefault,
        fontSize: '19px',
        lineHeight: '0',
      },
      [up('xl')]: {
        fontSize: '28px',
        lineHeight: '0',
      },
    },
    // only shows only L or larger
    navLogo: {
      fontFamily: FONTS.fontFamilyBold,
      textTransform: 'uppercase',
      fontSize: '25px',
      [up('xl')]: {
        fontSize: '38px',
      },
    },
    body: {
      fontFamily: FONTS.fontFamilyDefault,
      // fontSize: '28px',
      // lineHeight: '33.4px',
      fontSize: '14px',
      lineHeight: '16.7px',
      [up('md')]: {
        // fontSize: '45px',
        // lineHeight: '53.54px',
        fontSize: 23,
        lineHeight: '26.77px',
      },
      [up('lg')]: {
        fontSize: '25px',
        lineHeight: '25.18px',
      },
      [up('xl')]: {
        fontSize: '38px',
        lineHeight: '38.32px',
      },
    },
    projectName: {
      fontFamily: FONTS.fontFamilyBold,

      fontSize: 10,
      [up('md')]: {
        fontSize: 16,
      },
    },
    projectDate: {
      fontFamily: FONTS.fontFamilyDefault,

      fontSize: 10,

      [up('md')]: {
        fontSize: 16,
      },
    },
    sectionHeader: {
      fontFamily: FONTS.fontFamilyBold,
      textTransform: 'uppercase',
      fontSize: 15,
      lineHeight: '16.7px',
      [up('md')]: {
        fontSize: 21,
      },
      [up('lg')]: {
        fontSize: '23px',
        lineHeight: '25.18px',
      },
      [up('xl')]: {
        fontSize: '35px',
        lineHeight: '38.32px',
      },
    },
    section: {
      fontFamily: FONTS.fontFamilyDefault,
      fontSize: 15,
      lineHeight: '18.95px',
      [up('md')]: {
        // fontSize: '42px',
        // lineHeight: '54.13px',
        fontSize: 21,
        lineHeight: '27.07px',
      },
      [up('lg')]: {
        fontSize: '25px',
        lineHeight: '25.18px',
      },
      [up('xl')]: {
        fontSize: '38px',
        lineHeight: '38.32px',
      },
    },
    creditHeader: {
      fontFamily: FONTS.fontFamilyBold,
      textTransform: 'uppercase',
      // fontSize: '29px',
      fontSize: 15,
      [up('md')]: {
        // fontSize: '42px',
        fontSize: 21,
      },
      [up('lg')]: {
        fontSize: '17px',
      },
      [up('xl')]: {
        fontSize: '24px',
      },
    },
    creditTitle: {
      fontFamily: FONTS.fontFamilyBold,
      // fontSize: '17px',
      fontSize: 9,
      [up('md')]: {
        // fontSize: '24px',
        fontSize: 12,
      },
      [up('lg')]: {
        fontSize: '17px',
      },
      [up('xl')]: {
        fontSize: '24px',
      },
    },
    credit: {
      fontFamily: FONTS.fontFamilyDefault,
      // fontSize: '17px',
      // lineHeight: '19.76px',
      fontSize: 9,
      lineHeight: '9.88px',
      [up('md')]: {
        // fontSize: '24px',
        // lineHeight: '28.34px',
        fontSize: 12,
        lineHeight: '14.17px',
      },
      [up('lg')]: {
        fontSize: '17px',
      },
      [up('xl')]: {
        fontSize: '24px',
      },
    },
    projectPageProjectName: {
      fontFamily: FONTS.fontFamilyBold,
      fontSize: 12,

      [up('md')]: {
        fontSize: 22,
      },
      [up('lg')]: {
        fontSize: 25,
      },
      [up('xl')]: {
        fontSize: 34,
      },
    },
    projectPageProjectDate: {
      fontFamily: FONTS.fontFamilyDefault,
      fontSize: 12,
      [up('md')]: {
        fontSize: 22,
      },
      [up('lg')]: {
        fontSize: 25,
      },
      [up('xl')]: {
        fontSize: 34,
      },
    },
    projectPageProjectCaption: {
      fontFamily: FONTS.fontFamilyDefault,
      fontSize: 12,
      [up('md')]: {
        fontSize: 22,
      },
      [up('lg')]: {
        fontSize: 18,
      },
      [up('xl')]: {
        fontSize: 28,
      },
    },
    projectPageProjectCounter: {
      fontFamily: FONTS.fontFamilyDefault,
      fontSize: 12,
      [up('md')]: {
        fontSize: 22,
      },
      [up('lg')]: {
        fontSize: 18,
      },
      [up('xl')]: {
        fontSize: 28,
      },
    },
  },
};

export default theme;
