import React from 'react';
import { Helmet } from 'react-helmet';

import MainLayout from '../components/layouts/main-layout/MainLayout';
import Nav from '../components/sections/nav/Nav';
import Logo from '../components/sections/logo/Logo';
import Info from '../components/sections/info/Info';
import MobileIntro from '../components/sections/mobile-intro/MobileIntro';
import ProjectsList from '../components/sections/projects-list/ProjectsList';
import SelectedClients from '../components/sections/selected-clients/SelectedClients';
import Contact from '../components/sections/contact/Contact';
import Credits from '../components/sections/credits/Credits';

const Home = () => {
  const desktopLogoCloneRef = React.useRef();
  const desktopLogoRef = React.useRef();

  const mobileIntroCloneRef = React.useRef();
  const mobileIntroRef = React.useRef();

  const [isScreenMobile, setIsScreenMobile] = React.useState(0);

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsScreenMobile(window.innerWidth < 1280);

    window.addEventListener('resize', updateWindowDimensions);

    setIsLoaded(true);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  // React.useEffect(() => {
  //   window.addEventListener('resize', updateWindowDimensions);
  //   return () => window.removeEventListener('resize', updateWindowDimensions);
  // }, []);

  const updateWindowDimensions = () => {
    setIsScreenMobile(window.innerWidth < 1280);
  };

  React.useEffect(() => {
    // if (!isLoaded) return;
    window.addEventListener('scroll', updateScrollPositions, { passive: true });
    return () => window.removeEventListener('scroll', updateScrollPositions);
  }, [isLoaded]);

  const updateScrollPositions = () => {
    const isAtTopScroll = window.scrollY === 0;

    const isAtBottomScroll =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    // top
    const refToReference = isScreenMobile ? mobileIntroRef : desktopLogoRef;

    // bottom
    const refToCloneReference = isScreenMobile
      ? mobileIntroCloneRef
      : desktopLogoCloneRef;

    if (isAtTopScroll) {
      window.scrollTo(0, document.body.scrollHeight - 1);
      // window.scrollTo({ top: refToCloneReference.current.offsetTop });
    }

    if (isAtBottomScroll) {
      console.log({
        isAtBottomScroll,
        isAtTopScroll,
        refToReference,
        refToReferenceTop: refToReference.current.offsetTop,
        refToCloneReference,
        refToCloneReferenceTop: refToCloneReference.current.offsetTop,
      });
      window.scrollTo({
        top: 1,
      });
    }
  };

  const [test, setTest] = React.useState(0);
  return (
    <>
      <MainLayout>
        <Helmet>
          <title>New Body</title>
          <link rel="canonical" href="http://new-body.nyc" />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/favicon/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/favicon/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/favicon/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/favicon/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/favicon/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/favicon/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/favicon/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/favicon/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/favicon/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicon/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <Nav setTest={setTest} />
        <div ref={mobileIntroRef} id="linkForInfoMobile">
          <MobileIntro />
        </div>
        <div ref={desktopLogoRef}>
          <Logo />
        </div>
        <Info />
        <ProjectsList />
        <SelectedClients />
        <Contact />
        <Credits />
        <div ref={desktopLogoCloneRef}>
          <Logo />
        </div>
        <div ref={mobileIntroCloneRef}>
          <MobileIntro />
        </div>
      </MainLayout>
    </>
  );
};

export default Home;
