import React from 'react';

import styled from 'styled-components';

import Typography from '../../../design-system/component-library/Typography';
import theme from '../../../design-system/theme';

const selectedClients = [
  'Anna Molinari · ',
  'Asics · ',
  'Audemars Piguet · ',
  'Awake NY · ',
  'Baque Creative · ',
  'Boiler Room · ',
  'Century 21 - Next Century · ',
  'Dieu Donné · ',
  'Domicile · ',
  'Dover Street Market · ',
  'FM/S · ',
  'Juventus · ',
  'Le Bon Marché · ',
  'Madelon Group · ',
  'Makespace · ',
  'Medea · ',
  // 'Procell · ',
  'Selfridges · ',
  'Spincar · ',
  'The Graduate Center, CUNY · ',
  'Studio One',
  // 'Connor Monahan · ',
  // 'Michael Quigley',
];

const Wrapper = styled.section(() => ({
  backgroundColor: theme.colors.red,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 40,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.colors.blue,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 60,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.colors.white,
    paddingLeft: 162,
    paddingRight: 162,
    paddingBottom: 190,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.colors.green,
    paddingLeft: 243,
    paddingRight: 243,
    paddingBottom: 285,
  },
}));

const Header = styled.header(() => ({
  paddingBottom: 5,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 17,
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: 0,
  },
}));

const SelectedClients = () => {
  return (
    <Wrapper id="selected-clients-section">
      <Header>
        <Typography variant="sectionHeader">Selected clients</Typography>
      </Header>
      <div>
        {selectedClients.map((selectedClientName) => (
          <Typography variant="section">{selectedClientName}</Typography>
        ))}
      </div>
    </Wrapper>
  );
};

export default SelectedClients;
