import React from 'react';

import { ProjectContainer, TitleContainer } from './ProjectPreview.styles';

import Typography from '../../../../../design-system/component-library/Typography';

import ProjectPage from '../project-page/ProjectPage';

const ProjectPreview = ({
  title,
  date,
  caption,
  projectType,
  imageUrl,
  index,
  openProjectPageAndSelectProject,
}) => {
  const [isPreviewHovered, setIsPreviewHovered] = React.useState(false);
  return (
    <>
      <ProjectContainer
        projectType={projectType}
        onClick={() => openProjectPageAndSelectProject(index)}
        onMouseEnter={() => setIsPreviewHovered(true)}
        onMouseLeave={() => setIsPreviewHovered(false)}
      >
        <img src={imageUrl} />
        <TitleContainer>
          <Typography
            variant="projectName"
            isPreview
            isPreviewHovered={isPreviewHovered}
          >
            {title}{' '}
          </Typography>
          <Typography
            variant="projectDate"
            isPreview
            isPreviewHovered={isPreviewHovered}
          >
            {date}
          </Typography>
        </TitleContainer>
      </ProjectContainer>
      {/* )} */}
    </>
  );
};

export default ProjectPreview;
